<template>
  <div>
    <b-card class="bg-gradient-primary text-white">
      <b-row>
        <b-col class="text-center">
          <div class="font-weight-bold">
            Müşteri Adı
          </div>
          <div class="font-medium-4">
            {{ customer.name }}
          </div>
          <div
            v-if="customer.company_name"
            class="font-weight-light font-small-2"
          >
            {{ customer.company_name }}
          </div>
        </b-col>
        <b-col class="text-center">
          <div class="font-weight-bold">
            Müşteri Telefon
          </div>
          <div class="font-medium-4">
            {{ customer.phone }}
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="SMS İçeriği">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Ruhsat Sahibi"
            label-for="customer_name"
          >
            <b-form-input
              id="customer_name"
              v-model="customer_name"
              placeholder="Ruhsat Sahibi"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Yapılacak İşlemler"
            label-for="license_owner"
          >
            <v-select
              v-model="service_types"
              :options="serviceType"
              label="title"
              :reduce="item => item.title"
              multiple
              placeholder="Yapılacak İşlemler"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="İşlem Tutarı"
            label-for="price"
          >
            <b-input-group>
              <cleave
                id="price"
                v-model="price"
                class="form-control"
                :options="options.price"
                :raw="false"
                placeholder="İşlem Tutarı"
              />
              <b-input-group-append is-text>
                ₺
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="price > 0 && service_types.length > 0"
          cols="12"
        >
          <b-form-group
            label="Mesaj İçeriği"
            label-for="text"
          >
            <b-form-textarea
              id="text"
              v-model="serviceSms.text"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            variant="success"
            :disabled="(price <= 0 && service_types.length === 0) || smsSending"
            @click="sendMessage"
          >
            <FeatherIcon icon="MailIcon" />
            SMS Gönder
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <pre-loading v-if="smsSending" />
    <b-card
      v-if="smsSendResult.status !== null"
      class="mt-2"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group label="Sms Onay Kodu">
            <b-input-group>
              <b-form-input
                v-model="serviceSms.code"
                placeholder="Onay Kodu"
              />
              <b-input-group-append>
                <b-button
                  variant="danger"
                  @click="smsConfirm"
                >
                  Onayla
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-alert
              v-if="codeError"
              variant="danger"
              class="mt-2"
              show
            >
              <div class="alert-body text-center">
                {{ codeError }}
              </div>
            </b-alert>
            <b-alert
              class="mt-2"
              show
            >
              <div class="alert-body">
                <b-row class="match-height d-flex align-items-center">
                  <b-col>
                    Kalan Süre: {{ countDown }}
                  </b-col>
                  <b-col
                    class="text-right"
                    cols="auto"
                  >
                    <b-button
                      variant="info"
                      size="sm"
                      disabled
                      @click="sendMessage"
                    >
                      Tekrar Sms Gönder
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-alert>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BInputGroupAppend, BRow, BButton, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    BButton,
    BAlert,
    vSelect,
    Cleave,
    PreLoading,
  },
  data() {
    return {
      codeError: null,
      smsSending: false,
      countDown: 120,
      customer_name: null,
      price: null,
      service_types: [],
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    serviceSms() {
      return this.$store.getters['serviceSms/getServiceSms']
    },
    smsSendResult() {
      return this.$store.getters['serviceSms/smsSendResult']
    },
    smsConfirmResult() {
      return this.$store.getters['serviceSms/smsConfirmResult']
    },
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    serviceType() {
      return this.$store.getters['serviceSmsTypes/getServiceSmsTypes']
    },
  },
  watch: {
    customer_name(val) {
      if (val) {
        this.setSmsText()
      }
    },
    service_types(val) {
      if (val) {
        this.setSmsText()
      }
    },
    price(val) {
      if (val) {
        this.setSmsText()
      }
    },
  },
  created() {
    this.$store.commit('serviceSms/SET_SMS_SEND_RESULT', {
      status: null,
      error: null,
    })
    this.$store.commit('serviceSms/SET_SMS_CONFIRM_RESULT', {
      status: null,
    })
    this.getCustomer()
    this.getServiceTypes()
  },
  methods: {
    setSmsText() {
      this.serviceSms.text = `Sn. ${this.customer_name} Yapılacak ${this.price} TL tutarındaki ${this.service_types.join(', ')} işlemi için onay kodu: 000 000 Aydinlatma Metni: https://inll.app/kvkk Inallar Oto. San. ve Tic. A.S. Mersis No: 0475060624900001`
    },
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.$route.params.id)
        .then(res => {
          if (res.id) {
            this.customer_name = res.name
          }
        })
    },
    getServiceTypes() {
      this.$store.dispatch('serviceSmsTypes/serviceSmsTypesList', {
        select: [
          'com_service_sms_type.id AS id',
          'com_service_sms_type.title AS title',
        ],
        where: { 'com_service_sms_type.status': 1 },
        order_by: ['com_service_sms_type.id', 'ASC'],
      })
    },
    sendMessage() {
      this.smsSending = true
      this.$store.dispatch('serviceSms/sendSms', {
        id_com_customer: this.$route.params.id,
        smstext: this.serviceSms.text,
      })
        .then(res => {
          if (res) {
            this.countDown = 120
            this.countDownTimer()
            this.smsSending = false
          }
        })
    },
    smsConfirm() {
      this.codeError = null
      this.$store.dispatch('serviceSms/smsConfirm', {
        code: this.serviceSms.code,
      })
        .then(res => {
          if (res) {
            this.$router.push(`/service/sms/view/${res}`)
          } else {
            this.codeError = 'Hata onay kodu geçersiz.'
          }
        })
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
  },
}
</script>
